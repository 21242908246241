import { Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../../interfaces/interfaces';
import { ButtonCapYeiComponent } from '../button/button.component';
import { getEnvironment } from '../../environment/environment';
import { XtrasService } from '../../services/xtras.service';

@Component({
  selector: 'app-terms-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    ButtonCapYeiComponent,
  ],
  templateUrl: './terms-modal.component.html',
  styleUrl: './terms-modal.component.scss'
})
export class TermsModalComponent implements OnInit, OnDestroy{

  env = getEnvironment();

  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    public xServices: XtrasService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData | any

  ) {

  }
  dismiss(): void {
    this.dialogRef.close();
  }
  ngAfterViewInit(){
    if (this.data?.classComponent) {
      this.renderer.addClass(this.elRef.nativeElement, this.data?.classComponent);
    }
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  getTerms(): string {
    return this.xServices.getTerms();
  }
}
